<template>
  <div class="card-form">
    <div class="card-list">
      <div
        class="card-item"
        :class="{ '-active' : isCardFlipped }"
      >
        <div class="card-item__side -front">
          <div
            ref="focusElement"
            class="card-item__focus"
            :class="{'-active' : focusElementStyle }"
            :style="focusElementStyle"
          />
          <div class="card-item__cover">
            <img
              :src="'/images/backgrounds/' + currentCardBackground + '.jpeg'"
              class="card-item__bg"
            >
          </div>
          <div class="card-item__wrapper">
            <div class="card-item__top">
              <img
                v-if="form.bank_logo"
                :src="'/images/banks/' + form.bank_logo"
                class="card-item__bank_logo"
              >
              <img
                v-else
                src="/images/chip.png"
                class="card-item__chip"
              >
              <div class="card-item__type">
                <transition name="slide-fade-up">
                  <img
                    v-if="form.cardType"
                    :key="form.cardType"
                    :src="'/images/cardtypes/' + form.cardType + '.png'"
                    alt=""
                    class="card-item__typeImg"
                  >
                </transition>
              </div>
            </div>
            <label
              ref="cardNumber"
              for="cardNumber"
              class="card-item__number"
            >
              <template v-if="form.cardType === 'amex'">
                <span
                  v-for="(n, $index) in amexCardMask"
                  :key="$index"
                >
                  <transition name="slide-fade-up">
                    <div
                      v-if="$index > 4 && $index < 14 && form.cardNumber.length > $index && n.trim() !== ''"
                      class="card-item__numberItem"
                    >*</div>
                    <div
                      v-else-if="form.cardNumber.length > $index"
                      :key="$index"
                      class="card-item__numberItem"
                      :class="{ '-active' : n.trim() === '' }"
                    >
                      {{ form.cardNumber[$index] }}
                    </div>
                    <div
                      v-else
                      :key="$index + 1"
                      class="card-item__numberItem"
                      :class="{ '-active' : n.trim() === '' }"
                    >{{ n }}</div>
                  </transition>
                </span>
              </template>
              <template v-else>
                <span
                  v-for="(n, num) in otherCardMask"
                  :key="num"
                >
                  <transition name="slide-fade-up">
                    <div
                      v-if="num > 4 && num < 15 && form.cardNumber.length > num && n.trim() !== ''"
                      class="card-item__numberItem"
                    >*</div>
                    <div
                      v-else-if="form.cardNumber.length > num"
                      :key="num"
                      class="card-item__numberItem"
                      :class="{ '-active' : n.trim() === '' }"
                    >
                      {{ form.cardNumber[num] }}
                    </div>
                    <div
                      v-else
                      :key="num + 1"
                      class="card-item__numberItem"
                      :class="{ '-active' : n.trim() === '' }"
                    >{{ n }}</div>
                  </transition>
                </span>
              </template>
            </label>
            <div class="card-item__content">
              <label
                ref="cardName"
                for="cardName"
                class="card-item__info"
              >
                <div class="card-item__holder">Kart Üzerindeki İsim</div>
                <transition name="slide-fade-up">
                  <div
                    v-if="form.nameSurname"
                    key="1"
                    class="card-item__name"
                  >
                    <transition-group name="slide-fade-right">
                      <span
                        v-for="(n, index) in form.nameSurname.replace(/\s\s+/g, ' ')"
                        :key="index + 1"
                        class="card-item__nameItem"
                      >{{ n }}</span>
                    </transition-group>
                  </div>
                  <div
                    v-else
                    key="2"
                    class="card-item__name"
                  >AD SOYAD</div>
                </transition>
              </label>
              <div
                ref="expirationDate"
                class="card-item__date"
              >
                <label
                  for="expirationDate"
                  class="card-item__dateTitle text-white"
                >TARİH</label>
                <label
                  for="expirationDate"
                  class="card-item__dateItem text-white"
                >
                  <transition name="slide-fade-up">
                    <span
                      v-if="form.expirationDate"
                    >{{ form.expirationDate }}</span>
                    <span
                      v-else
                      key="2"
                    >AA/YY</span>
                  </transition>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-item__side -back">
          <div class="card-item__cover">
            <img
              :src="'/images/backgrounds/' + currentCardBackground + '.jpeg'"
              class="card-item__bg"
            >
          </div>
          <div class="card-item__band" />
          <div class="card-item__cvv">
            <div class="card-item__cvvTitle">
              CVV
            </div>
            <div class="card-item__cvvBand">
              <span
                v-for="(n, $index) in form.cvv"
                :key="$index"
              >
                *
              </span>

            </div>
            <div class="card-item__type">
              <img
                v-if="form.cardType"
                :src="'/images/cardtypes/' + form.cardType + '.png'"
                class="card-item__typeImg"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-card class="payment-form">
      <b-form-group
        label="Kart Üzerindeki İsim"
        label-for="cardName"
      >
        <validation-provider
          #default="{ errors }"
          name="Kart Üzerindeki İsim"
          rules="required"
        >
          <b-form-input
            id="cardName"
            v-model="form.nameSurname"
            placeholder="Kart Üzerindeki İsim"
            :state="errors.length > 0 ? false:null"
            data-ref="cardName"
            autocomplete="off"
            @focus="focusInput"
            @blur="blurInput"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Kart Numarası"
        label-for="cardNumber"
      >
        <validation-provider
          #default="{ errors }"
          name="Kart Numarası"
          rules="required"
        >
          <cleave
            id="cardNumber"
            v-model="form.cardNumber"
            class="form-control"
            :raw="false"
            :options="options.creditCard"
            :state="errors.length > 0 ? false:null"
            placeholder="0000 0000 0000 0000"
            data-ref="cardNumber"
            autocomplete="off"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-row>
        <b-col>
          <b-form-group
            label="Son Kullanma Tarihi"
            label-for="date"
          >
            <validation-provider
              #default="{ errors }"
              name="Sk. Tarih"
              rules="required"
            >
              <cleave
                id="expirationDate"
                v-model="form.expirationDate"
                class="form-control"
                :raw="false"
                :options="options.date"
                :state="errors.length > 0 ? false:null"
                placeholder="AA/YY"
                data-ref="expirationDate"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="CVV"
            label-for="cardCvv"
          >
            <validation-provider
              #default="{ errors }"
              name="CVV"
              rules="required"
            >
              <cleave
                id="cardCvv"
                v-model="form.cvv"
                class="form-control"
                :raw="true"
                :options="options.cvv"
                :state="errors.length > 0 ? false:null"
                placeholder="CVV"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <!--<div  v-if="form.nameSurname && form.cardNumber && form.cardType && form.expirationDate && form.cvv"> -->
      <div>
        <b-form-group
          label="Ödenecek Tutar"
          label-for="payment"
        >
          <validation-provider
            #default="{ errors }"
            name="Ödenecek Tutar"
            rules="required"
          >
            <b-input-group append="₺">
              <cleave
                id="payment"
                v-model="form.amount"
                class="form-control"
                :raw="true"
                :state="errors.length > 0 ? false:null"
                :options="options.number"
                placeholder="Ödenecek Tutar"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <instalment v-if="form.amount > 0" />
        <b-alert
          v-if="form.pay_amount"
          variant="primary"
          show
          class="text-center mt-1"
        >
          <div class="alert-body">
            <div class="font-weight-light">
              Ödenecek Tutar
            </div>
            <div class="font-medium-3 font-weight-bolder">
              {{ form.pay_amount | toCurrency }} ₺
            </div>
          </div>
        </b-alert>
        <hr>
        <b-button
          :variant="(pay3dStatus)? 'success' : 'danger'"
          block
          :disabled="!form.selectInstallment"
          @click="submitPayment"
        >
          <FeatherIcon icon="CheckIcon" />
          {{ (pay3dStatus)? '3D Güvenli Ödeme' : 'Ödemeyi Tamamla' }}
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BInputGroup, BButton, BAlert,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import instalment from '@/views/Payment/Components/Instalment.vue'

export default {
  name: 'PaymentForm',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BAlert,
    Cleave,
    ValidationProvider,
    instalment,
  },
  data() {
    return {
      currentCardBackground: Math.floor(Math.random() * 25 + 1),
      amexCardMask: '#### ###### #####',
      otherCardMask: '#### #### #### ####',
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
      locale: 'tr',
      paymentType: 0,
      options: {
        creditCard: {
          uppercase: true,
          blocks: [4, 4, 4, 4],
          numericOnly: true,
        },
        cvv: {
          uppercase: true,
          blocks: [3],
          numericOnly: true,
        },
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['m', 'y'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
      paymentTypes: [
        { text: 'Tek Çekim', value: 0 },
        { text: 'Taksitli', value: 1 },
      ],
    }
  },
  computed: {
    form() {
      return this.$store.getters['payment/getPaymentForm']
    },
    installments() {
      return this.$store.getters['payment/getInstallments']
    },
    pay3dStatus() {
      return this.$store.getters['posConfigs/getPos_config_pay3d']
    },
    getPaymentSendForm() {
      return this.$store.getters['payment/getPaymentSendForm']
    },
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        const binNumber = val.cardNumber.replace(' ', '')
        if (binNumber.length === 5 || binNumber.length === 6) {
          this.$store.dispatch('payment/getCardType', {
            where: {
              'bins.bin_no': binNumber,
            },
          })
        }
        if (val.cardType === 'amex') {
          this.options.creditCard.blocks = [4, 6, 5]
          this.options.cvv.blocks = [4]
          if (val.cvv >= 1 && val.cvv.length < 4) {
            this.isCardFlipped = true
          } else {
            this.isCardFlipped = false
          }
        } else {
          this.options.creditCard.blocks = [4, 4, 4, 4]
          this.options.cvv.blocks = [3]
          if (val.cvv >= 1 && val.cvv.length < 3) {
            this.isCardFlipped = true
          } else {
            this.isCardFlipped = false
          }
        }
      },
    },
  },
  created() {
    localize(this.locale)
    this.getPosInstallments()
  },
  methods: {
    focusInput(e) {
      this.isInputFocused = true
      const targetRef = e.target.dataset.ref
      const target = this.$refs[targetRef]
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
      }
    },
    blurInput() {
      const vm = this
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null
        }
      }, 300)
      vm.isInputFocused = false
    },
    calcInstallment(rate) {
      const rateFloat = parseFloat(rate)
      const amount = parseFloat(this.form.amount)
      let result = amount
      if (amount) {
        if (rateFloat > 0) {
          result = amount + (amount * rateFloat) / 100
        }
      }
      return result
    },
    getPosInstallments() {
      this.$store.dispatch('posConfigs/pos_installments')
    },
    submitPayment() {
      this.$store.dispatch('payment/paymentSend', this.form)
      if (!this.pay3dStatus) {
        this.getPaymentSendForm.modal = true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
/* Code64 */
.payment-form{
  padding-top: 140px;
}
/* Code64 */
body {
  background: #ddeefc;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}
* {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
.wrapper {
  min-height: 100vh;
  display: flex;
  padding: 50px 15px;
  @media screen and (max-width: 700px), (max-height: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-form {
  max-width: 875px;
  margin: auto;
  width: 100%;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }

  &__inner {
    background: #fff;
    // box-shadow: 3px 13px 30px 0px rgba(21, 34, 67, 0.2);
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    padding: 35px;
    padding-top: 180px;

    @media screen and (max-width: 480px) {
      padding: 25px;
      padding-top: 165px;
    }
    @media screen and (max-width: 360px) {
      padding: 15px;
      padding-top: 165px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 150px;
      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: #2364d2;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;

  @media screen and (max-width: 480px) {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }

  @media screen and (max-width: 360px) {
    height: 180px;
  }

  &.-active {
    .card-item__side {
      &.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
        rotateZ(0deg);
      }
      &.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
        // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
      }
    }
  }

  &__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    &.-active {
      opacity: 1;
    }
  }

  &__side {
    border-radius: 15px;
    overflow: hidden;
    // box-shadow: 3px 13px 30px 0px rgba(11, 19, 41, 0.5);
    box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;

    &.-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
      z-index: 2;
      padding: 0;
      // background-color: #2364d2;
      // background-image: linear-gradient(
      //   43deg,
      //   #4158d0 0%,
      //   #8555c7 46%,
      //   #2364d2 100%
      // );
      height: 100%;

      .card-item__cover {
        transform: rotateY(-180deg)
      }
    }
  }
  &__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__cover {
    height: 100%;
    background-color: #1c1d27;
    position: absolute;
    height: 100%;
    background-color: #1c1d27;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(6, 2, 29, 0.45);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }

  &__bank_logo {
    margin-top: -10px;
    width: 120px;
    @media screen and (max-width: 480px) {
      width: 80px;
    }
    @media screen and (max-width: 360px) {
      width: 60px;
    }
  }

  &__chip {
    width: 60px;
    @media screen and (max-width: 480px) {
      width: 50px;
    }
    @media screen and (max-width: 360px) {
      width: 40px;
    }
  }

  &__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 40px;
      max-width: 90px;
    }
    @media screen and (max-width: 360px) {
      height: 30px;
    }
  }

  &__typeImg {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    object-position: top right;
  }

  &__info {
    color: #fff;
    width: 100%;
    max-width: calc(100% - 85px);
    padding: 10px 15px;
    font-weight: 500;
    display: block;

    cursor: pointer;

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  &__holder {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 6px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    font-family: "Source Code Pro", monospace;
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
    user-select: none;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  &__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
  }

  &__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    font-size: 27px;
    margin-bottom: 35px;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 21px;
      margin-bottom: 15px;
      padding: 10px 10px;
    }

    @media screen and (max-width: 360px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
  }

  &__numberItem {
    width: 16px;
    display: inline-block;
    &.-active {
      width: 30px;
    }

    @media screen and (max-width: 480px) {
      width: 13px;

      &.-active {
        width: 16px;
      }
    }

    @media screen and (max-width: 360px) {
      width: 12px;

      &.-active {
        width: 8px;
      }
    }
  }

  &__content {
    color: #fff;
    display: flex;
    align-items: flex-start;
  }

  &__date {
    flex-wrap: wrap;
    font-size: 18px;
    margin-left: auto;
    padding: 10px;
    display: inline-flex;
    width: 80px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__dateItem {
    position: relative;
    span {
      width: 22px;
      display: inline-block;
    }
  }

  &__dateTitle {
    opacity: 0.7;
    font-size: 13px;
    padding-bottom: 6px;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  &__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 360px) {
      height: 40px;
      margin-top: 10px;
    }
  }

  &__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 15px;
    .card-item__type {
      opacity: 0.7;
    }

    @media screen and (max-width: 360px) {
      padding: 10px 15px;
    }
  }
  &__cvvTitle {
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }
  &__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

    @media screen and (max-width: 480px) {
      height: 40px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }
}

.card-list {
  margin-bottom: -130px;

  @media screen and (max-width: 480px) {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 20px;
  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #1a3b5d;
    width: 100%;
    display: block;
    user-select: none;
  }
  &__input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;
    font-family: "Source Sans Pro", sans-serif;

    &:hover,
    &:focus {
      border-color: #3d9cff;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }
    &.-select {
      -webkit-appearance: none;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
      background-size: 12px;
      background-position: 90% center;
      background-repeat: no-repeat;
      padding-right: 30px;
    }
  }
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}
.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}
.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}

.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all .3s ease-in-out;

  @media screen and (min-width: 500px) {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }

  @media screen and (max-width: 700px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;

    &:active {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
